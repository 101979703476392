const fetchData = async (path: string, options?: RequestInit): Promise<any> => {
  try {
    const baseEndpoint =
      process.env.NODE_ENV === "development"
        ? "http://127.0.0.1:8686"
        : "https://contentstack-extensions-api.solostove.workers.dev";
    const url = `${baseEndpoint}${path}`;
    const token = btoa(`thine-aggeus-library-forty:zZAG_QdTM9q26decmm@W.E873J*H3W`);

    const apiOptions = {
      ...options,
      headers: {
        ...options?.headers,
        Authorization: `Basic ${token}`,
      },
    };
    const res = await fetch(url, apiOptions);
    if (!res.ok) {
      const errorData = await res.json();
      throw new Error(`${errorData.error_code}: ${errorData.error_message}`);
    }
    const json = await res.json();
    return Promise.resolve(json);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const AppApiInstance = { fetchData };